const Signin = '/';
const Dashboard = '/claimsmis';
const FraudDashboard = '/softfraudavoidance';
const DamageAnalysisDashboard = '/aamageanalysis'; 


export const BaseRoutes = {
    Signin,
    Dashboard,
    FraudDashboard,
    DamageAnalysisDashboard, 
}
