import React, { useState } from 'react';

function ReportTable({ data, isTableLoading }) {
  const reportData = data;
  const dailySummaries = reportData || [];

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Set default number of rows per page
  const [showAll, setShowAll] = useState(false); // New state for toggling "Show All" mode

  // Calculate total pages
  const totalPages = Math.ceil(dailySummaries.length / rowsPerPage);

  // Get the current rows for the current page
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = showAll ? dailySummaries : dailySummaries.slice(indexOfFirstRow, indexOfLastRow);

  console.log(currentRows[0]?.ro_office, "current rows log");
  

  // Pagination handlers
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleShowAllToggle = () => {
    if (showAll) {
      // Reset to paginated view
      setRowsPerPage(20);
      setCurrentPage(1);
    } else {
      // Show all rows
      setRowsPerPage(dailySummaries.length);
    }
    setShowAll(!showAll);
  };

  // Define styles as JavaScript objects
  const tableStyle = {
    width: '100%',
    tableLayout: 'fixed',
    fontSize: '14px',
  };

  const thTdStyle = {
    padding: '8px',
    border: '1px solid #ddd',
    textAlign: 'center',
    wordWrap: 'break-word',
  };

  const thStyle = {
    ...thTdStyle,
    backgroundColor: '#fff',
    fontWeight: 'bold',
  };

  const tdStyle = {
    ...thTdStyle,
    backgroundColor: '#fff',
  };

  const smallScreenStyle = {
    fontSize: '12px',
  };

  const isSmallScreen = window.innerWidth <= 768;

  // Loader inline style
  const loaderStyle = {
    border: '8px solid #f3f3f3',
    borderTop: '8px solid #007bff',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    animation: 'spin 1s linear infinite',
    margin: '20px auto',
  };

  // Keyframes for loader animation
  const keyframesStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  const paginationContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  };

  const paginationInfoStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const buttonStyle = {
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    padding: '8px 12px',
    margin: '0 5px',
    cursor: 'pointer',
    borderRadius: '4px',
  };

  const buttonDisabledStyle = {
    ...buttonStyle,
    backgroundColor: '#cccccc',
    cursor: 'not-allowed',
  };

  return (
    <div>
      {/* Add keyframes for spinner animation */}
      <style>{keyframesStyle}</style>

      {/* Pagination Details and "Show All" at the Top */}
      <div style={{
        ...paginationContainerStyle,
        backgroundColor: '#fff',
        padding: '10px 20px',
        borderRadius: '8px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
      }}>
        <div style={paginationInfoStyle}>
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
            {showAll
              ? `Showing all ${dailySummaries.length} rows`
              : `Page ${currentPage} of ${totalPages}, Rows per page: ${rowsPerPage}, total rows: ${dailySummaries.length}`}
          </span>
        </div>
        <button
          style={{
            ...buttonStyle,
            padding: '10px 15px',
            fontSize: '14px',
            borderRadius: '6px'
          }}
          onClick={handleShowAllToggle}
        >
          {showAll ? 'Show Paginated' : 'Show All'}
        </button>
      </div>

      {/* Loader */}
      {isTableLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={loaderStyle}></div>
        </div>
      )}

      {/* Table */}
      {!isTableLoading && (
        <table style={isSmallScreen ? { ...tableStyle, ...smallScreenStyle } : tableStyle}>
          <thead>
            <tr>
              <th style={thStyle} rowSpan="2">Sl.No.</th>
              {currentRows[0]?.ro_office ? <th style={thStyle} rowSpan="2">RO Office</th> : null}
              {currentRows[0]?.do_office ? <th style={thStyle} rowSpan="2">DO Office</th> : null}
              {currentRows[0]?.bo_office ? <th style={thStyle} rowSpan="2">BO Office</th> : null}
              <th style={thStyle} colSpan="5">Claims - Numbers</th>
              <th style={thStyle} colSpan="5">Claims - Amount</th>
            </tr>
            <tr>
              <th style={thStyle}>Total Claims</th>
              <th style={thStyle}>Outstanding Claims</th>
              <th style={thStyle}>Outstanding %</th>
              <th style={thStyle}>Settled Claims</th>
              <th style={thStyle}>Settled %</th>
              <th style={thStyle}>Total Claims Amount</th>
              <th style={thStyle}>Outstanding Claims Amount</th>
              <th style={thStyle}>Settled Claims Amount</th>
              <th style={thStyle}>% Settled Claims Amount</th>
              <th style={thStyle}>% Outstanding Claims Amount</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.length === 0 ? (
              <tr>
                <td colSpan="11" style={{ textAlign: 'center', backgroundColor: '#fff' }}>
                  No Data
                </td>
              </tr>
            ) : (
              currentRows.map((row, index) => (
                <tr key={index}>
                  <td style={tdStyle}>{indexOfFirstRow + index + 1}</td>
                  {currentRows[0]?.ro_office ? <td style={tdStyle} >{row.ro_office}</td> : null}
                  {currentRows[0]?.do_office ? <td style={tdStyle} >{row.do_office}</td> : null}
                  {currentRows[0]?.bo_office ? <td style={tdStyle} >{row.bo_office}</td> : null}
                  <td style={tdStyle}>{row.total_claims}</td>
                  <td style={tdStyle}>{row.o_s_claims}</td>
                  <td style={tdStyle}>{row.o_s_percentage.toFixed(2) + " %"}</td>
                  <td style={tdStyle}>{row.settled_claims}</td>
                  <td style={tdStyle}>{row.settled_percentage.toFixed(2) + " %"}</td>
                  <td style={tdStyle}>{row.total_claims_amount}</td>
                  <td style={tdStyle}>{row.o_s_claims_amount}</td>
                  <td style={tdStyle}>{row.settled_claims_amount}</td>
                  <td style={tdStyle}>{row.settled_percentage_amount.toFixed(2) + " %"}</td>
                  <td style={tdStyle}>{row.o_s_percentage_amount.toFixed(2) + " %"}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      )}

      {/* Pagination Controls at the Bottom */}
      {!showAll && (
        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          <button
            style={currentPage === 1 ? buttonDisabledStyle : buttonStyle}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
          <button
            style={currentPage === totalPages ? buttonDisabledStyle : buttonStyle}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
}

export default ReportTable;
