import React, { useEffect, useState } from 'react'

function Loader() {
  const [loadingText, setLoadingText] = useState('Loading')

  useEffect(() => {
    const LoadingStates = ['Loading', 'Loading.', 'Loading..', 'Loading...']

    
    var index = 0;
    const interval = setInterval(() => {
        setLoadingText(LoadingStates[index]);
        index = (index + 1) % LoadingStates.length;
    }, 200);

    return () => clearInterval(interval);
  }, []);
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '94vh' }}>{loadingText}</div>
  )
}

export default Loader