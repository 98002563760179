import React, { useEffect, useState } from 'react';
import './App.css';
import FraudClaimsChart from './FraudClaimsChart';
import FraudReportTable from './FraudReportTable';
import axios from 'axios';

function Fraud() {
  const [clientOptions, setClientOptions] = useState([]);
  const dataOne = [
    { name: 'Private Car', TotalEstimateAmount: 550, EstimateAfterDepreciation: 1950, SettledAmount: 2000 },
    { name: 'Two Wheeler', TotalEstimateAmount: 5000, EstimateAfterDepreciation: 1000, SettledAmount: 2000 },
    { name: 'Commercial', TotalEstimateAmount: 5000, EstimateAfterDepreciation: 1000, SettledAmount: 2000 },
  ];

  const dataTwo = [
    { name: 'OfficeONE', TotalEstimateAmount: 550, EstimateAfterDepreciation: 1950, SettledAmount: 2000 },
    { name: 'OfficeTWO', TotalEstimateAmount: 5000, EstimateAfterDepreciation: 1000, SettledAmount: 2000 },
    { name: 'OfficeTHREE', TotalEstimateAmount: 5000, EstimateAfterDepreciation: 1000, SettledAmount: 2000 },
  ];

  const dataThree = [
    { name: 'OEM', TotalEstimateAmount: 550, EstimateAfterDepreciation: 1950, SettledAmount: 2000 },
    { name: 'NON-OEM', TotalEstimateAmount: 5000, EstimateAfterDepreciation: 1000, SettledAmount: 2000 },
  ];

  const dataFour = [
    { name: 'OEM', TotalEstimateAmount: 550, EstimateAfterDepreciation: 1950, SettledAmount: 2000 },
    { name: 'NON-OEM', TotalEstimateAmount: 5000, EstimateAfterDepreciation: 1000, SettledAmount: 2000 },
  ];

  // const clientApi = [
  //   "All",
  //   "United India Insurance Co. Ltd.",
  //   "National Insurance Co Ltd"
  // ];

  const apiUrl = 'http://localhost:8001/';
  
  const fetchClientData = async () => {
    try {
      const response = await axios.get('http://localhost:8001/clients/', {
        headers: {
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });
      setClientOptions(response.data);
    } catch (error) {
      console.error(error.message, "error print");
    }
  };

  useEffect(() => {
    fetchClientData()
  }, [])
  
  return (
    <div className="App" style={{backgroundColor: '#e7e7e7', height: '100vh'}}>
      <h1>Fraud MIS Report</h1>
      <div className="container">
        <div className="filter-section">
          <div className="row mb-3 align-items-center">
            <div className="col-md-4 text-end">
              <label className="form-label">Client:</label>
            </div>
            <div className="col-md-4">
              <select className="form-control">
                {clientOptions.map((item, index) => (
                  <option value={item} key={index}>{item}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-3 align-items-center">
            <div className="col-md-4 text-end">
              <label className="form-label">Office:</label>
            </div>
            <div className="col-md-2">
              <select className="form-control">
                <option value="Regional">Regional Office</option>
              </select>
            </div>
            <div className="col-md-2">
              <select className="form-control">
                <option value="Division">Division Office</option>
              </select>
            </div>
            <div className="col-md-2">
              <select className="form-control">
                <option value="BranchPortal">Branch/Portal Office</option>
              </select>
            </div>
          </div>
          <div className="row mb-3 align-items-center">
            <div className="col-md-2 text-end">
              <label className="form-label">Vehicle Type:</label>
            </div>
            <div className="col-md-4">
              <select className="form-control">
                <option value="Private Car">Private Car</option>
              </select>
            </div>

            <div className="col-md-2 text-end">
              <label className="form-label">Vehicle Make:</label>
            </div>
            <div className="col-md-4">
              <select className="form-control">
                <option value="all">ALL</option>
              </select>
            </div>
          </div>
          <div className="row mb-3 align-items-center">
            <div className="col-md-2 text-end">
              <label className="form-label">Workshop Type:</label>
            </div>
            <div className="col-md-4">
              <select className="form-control">
                <option value="Private Car">ALL</option>
              </select>
            </div>

            <div className="col-md-2 text-end">
              <label className="form-label">Policy Type:</label>
            </div>
            <div className="col-md-4">
              <select className="form-control">
                <option value="all">ALL</option>
              </select>
            </div>
          </div>
          <div className="row mb-3 align-items-center">
            <div className="col-md-2 text-end">
              <label className="form-label">From Date:</label>
            </div>
            <div className="col-md-4">
              <input type="date" className="form-control" />
            </div>

            <div className="col-md-2 text-end">
              <label className="form-label">To Date:</label>
            </div>
            <div className="col-md-4">
              <input type="date" className="form-control" />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col"style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button className="btn btn-primary ms-2">Search</button>
            </div>
          </div>
        </div>
      </div>

      <FraudReportTable />

      <div style={{
        display: 'flex',
        justifyContent: 'evenly',
        alignItems: 'center',
        // flexWrap: 'wrap',
        // height: '100vh',
        gap: '16px'
      }}>
        <div style={{ flex: '1 1 calc(50% - 16px)', boxSizing: 'border-box' }} className="mt-3">
          <FraudClaimsChart
            data={dataOne}
            bars={[
              { dataKey: "TotalEstimateAmount", fill: "#8884d8" },
              { dataKey: "EstimateAfterDepreciation", fill: "#82ca9d" },
              { dataKey: "SettledAmount", fill: "#ECDFCC" },
            ]}
          />
        </div>

        <div style={{ flex: '1 1 calc(50% - 16px)', boxSizing: 'border-box' }} className="mt-3">
          <FraudClaimsChart
            data={dataTwo}
            bars={[
              { dataKey: "TotalEstimateAmount", fill: "#8884d8" },
              { dataKey: "EstimateAfterDepreciation", fill: "#82ca9d" },
              { dataKey: "SettledAmount", fill: "#ECDFCC" },
            ]}
          />
        </div>

        <div style={{ flex: '1 1 calc(50% - 16px)', boxSizing: 'border-box' }} className="mt-3">
          <FraudClaimsChart
            data={dataThree}
            bars={[
              { dataKey: "TotalEstimateAmount", fill: "#8884d8" },
              { dataKey: "EstimateAfterDepreciation", fill: "#82ca9d" },
              { dataKey: "SettledAmount", fill: "#ECDFCC" },
            ]}
          />
        </div>

        <div style={{ flex: '1 1 calc(50% - 16px)', boxSizing: 'border-box' }} className="mt-3">
          <FraudClaimsChart
            data={dataFour}
            bars={[
              { dataKey: "TotalEstimateAmount", fill: "#8884d8" },
              { dataKey: "EstimateAfterDepreciation", fill: "#82ca9d" },
              { dataKey: "SettledAmount", fill: "#ECDFCC" },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Fraud;
