import React, { useEffect, useState } from "react";
import ReportTable from "./ReportTable";
import "./App.css";
import ClaimsChart from "./ClaimsChart";
import Fraud from "./Fraud";
import LoginScreen from "./LoginScreen";
import axios from "axios";
import Loader from "./loader/Loader";
import DamageAnalysisTable from "./tables/DamageAnalysisTables";

function DamageAnalysis() {
  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [clientOptions, setClientOptions] = useState([]);
  const [VehicleTypes, setVehicleTypes] = useState([]);
  const [WorkShopTypes, setWorkShopTypes] = useState([]);
  const [VehicleMakesTypes, setVehicleMakesTypes] = useState([]);
  const [DistinctPolicyTypes, setDistinctPolicyTypes] = useState([]);
  const [claimsSurveyRange, setClaimsSurveyRange] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const apiUrl = "http://localhost:8001/";

  const fetchClientData = async () => {
    try {
      const clientResponse = await axios.get(`${apiUrl}clients/`, {
        headers: {
          "Content-Type": "application/json",
          //   'ngrok-skip-browser-warning': 'true'
        },
      });
      setClientOptions(clientResponse.data);
    } catch (error) {
      console.error(error.message, "error print");
    }
  };

  const fetchVehicleData = async () => {
    try {
      const VehicleResponse = await axios.get(`${apiUrl}vehicle-types/`, {
        headers: {
          "Content-Type": "application/json",
          // 'ngrok-skip-browser-warning': 'true'
        },
      });
      setVehicleTypes(VehicleResponse.data);
    } catch (error) {
      console.error(error.message, "error print");
    }
  };

  const fetchWorkShopData = async () => {
    try {
      const WorkShopResponse = await axios.get(`${apiUrl}workshop-types/`, {
        headers: {
          "Content-Type": "application/json",
          // 'ngrok-skip-browser-warning': 'true'
        },
      });
      setWorkShopTypes(WorkShopResponse.data);
    } catch (error) {
      console.error(error.message, "error print");
    }
  };

  const fetchVehicleMakesData = async () => {
    try {
      const VehicleMakesResponse = await axios.get(`${apiUrl}vehicle-makes/`, {
        headers: {
          "Content-Type": "application/json",
          // 'ngrok-skip-browser-warning': 'true'
        },
      });
      setVehicleMakesTypes(VehicleMakesResponse.data);
    } catch (error) {
      console.error(error.message, "error print");
    }
  };

  const fetchDistinctPolicyData = async () => {
    try {
      const DistinctPolicyResponse = await axios.get(
        `${apiUrl}distinct-policy-types/`,
        {
          headers: {
            "Content-Type": "application/json",
            // 'ngrok-skip-browser-warning': 'true'
          },
        }
      );
      setDistinctPolicyTypes(DistinctPolicyResponse.data);
    } catch (error) {
      console.error(error.message, "error print");
    }
  };

  const fetchClaimsSurvey = async (fromDate, toDate) => {
    try {
      setIsTableLoading(true);
      const claimsSurveyResponse = await axios.get(
        `${apiUrl}claims-summary/?start_date=${fromDate}&end_date=${toDate}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setClaimsSurveyRange(claimsSurveyResponse.data);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsTableLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    try {
      await Promise.all([
        fetchClientData(),
        fetchVehicleData(),
        fetchWorkShopData(),
        fetchVehicleMakesData(),
        fetchDistinctPolicyData(),
      ]);
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData()
  }, []);

  const ClaimsdataOne = [
    { name: "Private Car", outstanding: 550, settled: 1950 },
    { name: "Office Car", outstanding: 5000, settled: 1000 },
  ];

  const ClaimsdataTwo = [
    { name: "Private Car", outstanding: 550, settled: 1950 },
    { name: "Office Car", outstanding: 5000, settled: 1000 },
  ];

  const ClaimsdataThree = [
    { name: "Private Car", outstanding: 550, settled: 1950 },
    { name: "Office Car", outstanding: 5000, settled: 1000 },
  ];

  return (
    <div style={{
      backgroundColor: '#e7e7e7', height: '100vh'
    }}>
      <h1>Damage Analysis Report</h1>

      {/* Filter Section */}
      {
      isLoading ? (
        <Loader />
      ) :
      (
        <>
        <div className="container">
        <div className="filter-section">
          <div className="row mb-3 align-items-center">
            <div className="col-md-4 text-end">
              <label className="form-label">Client:</label>
            </div>
            <div className="col-md-4">
              <select className="form-control">
                {clientOptions.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-3 align-items-center">
            <div className="col-md-4 text-end">
              <label className="form-label">Office:</label>
            </div>
            <div className="col-md-2">
              <select className="form-control">
                <option value="Regional">Regional Office</option>
              </select>
            </div>
            <div className="col-md-2">
              <select className="form-control">
                <option value="Division">Division Office</option>
              </select>
            </div>
            <div className="col-md-2">
              <select className="form-control">
                <option value="BranchPortal">Branch/Portal Office</option>
              </select>
            </div>
          </div>
          <div className="row mb-3 align-items-center">
            <div className="col-md-2 text-end">
              <label className="form-label">Vehicle Type:</label>
            </div>
            <div className="col-md-4">
              <select className="form-control">
                {VehicleTypes.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-2 text-end">
              <label className="form-label">Vehicle Make:</label>
            </div>
            <div className="col-md-4">
              <select className="form-control">
                {VehicleMakesTypes.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-3 align-items-center">
            <div className="col-md-2 text-end">
              <label className="form-label">Workshop Type:</label>
            </div>
            <div className="col-md-4">
              <select className="form-control">
                {WorkShopTypes.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-2 text-end">
              <label className="form-label">Policy Type:</label>
            </div>
            <div className="col-md-4">
              <select className="form-control">
                {DistinctPolicyTypes.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-3 align-items-center">
            <div className="col-md-2 text-end">
              <label className="form-label">From Date:</label>
            </div>
            <div className="col-md-4">
              <input
                type="date"
                className="form-control"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>

            <div className="col-md-2 text-end">
              <label className="form-label">To Date:</label>
            </div>
            <div className="col-md-4">
              <input
                type="date"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div
              className="col"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <button
                className="btn btn-primary ms-2"
                onClick={() => fetchClaimsSurvey(fromDate, toDate)}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <DamageAnalysisTable data = {claimsSurveyRange} isTableLoading={isTableLoading}/>

      {/* {isTableLoading ? ( // Show the loader when table data is loading
        <Loader />
      ) : claimsSurveyRange.length === 0 ? ( // Show 'No Data' when the table data is empty
        <div>No Data</div>
      ) : (
        <ReportTable data={claimsSurveyRange} />
      )} */}

      {/* Chart Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 50,
        }}
      >
        <div className="mt-3">
          <ClaimsChart
            data={ClaimsdataOne}
            bars={[
              { dataKey: "outstanding", fill: "#8884d8" },
              { dataKey: "settled", fill: "#ECDFCC" },
            ]}
          />
        </div>

        <div className="mt-3">
          <ClaimsChart
            data={ClaimsdataTwo}
            bars={[
              { dataKey: "outstanding", fill: "#8884d8" },
              { dataKey: "settled", fill: "#ECDFCC" },
            ]}
          />
        </div>

        <div className="mt-3">
          <ClaimsChart
            data={ClaimsdataThree}
            bars={[
              { dataKey: "outstanding", fill: "#8884d8" },
              { dataKey: "settled", fill: "#ECDFCC" },
            ]}
          />
        </div>

        <div className="mt-3">
          <ClaimsChart
            data={ClaimsdataThree}
            bars={[
              { dataKey: "outstanding", fill: "#8884d8" },
              { dataKey: "settled", fill: "#ECDFCC" },
            ]}
          />
        </div>
      </div>
      </>
    )}
    </div>
  );
}
export default DamageAnalysis;
