// ClaimsChart.js
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const ClaimsChart = ({ data, bars }) => {
  return (
    <BarChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      {bars.map((bar, index) => (
        <Bar key={index} dataKey={bar.dataKey} stackId="a" fill={bar.fill} />
      ))}
    </BarChart>
  );
};

export default ClaimsChart;
