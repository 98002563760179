import React, { useEffect, useState } from "react";
import ReportTable from "./ReportTable";
import "./App.css";
import ClaimsChart from "./ClaimsChart";
import Fraud from "./Fraud";
import LoginScreen from "./LoginScreen";
import axios from "axios";
import Loader from "./loader/Loader";
import { apiUrl } from "./CommonConfig/Apicall";

function Dashboard() {

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [clientOptions, setClientOptions] = useState([]);
  const [ROOptions, setROOptions] = useState([]);
  const [DOOptions, setDOOptions] = useState([]);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [BOOptions, setBOOptions] = useState([]);
  const [VehicleTypes, setVehicleTypes] = useState([]);
  const [WorkShopTypes, setWorkShopTypes] = useState([]);
  const [VehicleMakesTypes, setVehicleMakesTypes] = useState([]);
  const [VehicleMakeTypes, setVehicleMakeTypes] = useState([]);

  const [PolicyTypes, setPolicyTypes] = useState([]);
  const [DistinctPolicyTypes, setDistinctPolicyTypes] = useState([]);
  const [claimsSurveyRange, setClaimsSurveyRange] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [ro_office, setRO_office] = useState("");
  const [do_office, setDO_office] = useState("");
  const [bo_office, setBO_office] = useState("");
  const [client, setClient] = useState("");
  const [workshop_type, setWorkshop_type] = useState("");
  const [vehicle_type, setVehicle_type] = useState("");
  const [claimsDataOne, setClaimsDataOne] = useState([]);
  const [claimsDataTwo, setClaimsDataTwo] = useState([]);
  const [claimsDataThree, setClaimsDataThree] = useState([]);
  const [claimsDataFour, setClaimsDataFour] = useState([]);




  const fetchBarCharts = async () => {
    console.log(clientOptions[0].id, toDate, fromDate, "hellooooooooooooooooo");
    if (!client) {
      alert("please select a valid Client!")
      return;
    }
    try {
      const response = await axios.get('https://reportsapi.axionpcs.in/bar_chart', {
        params: {
          client: clientOptions[0].id,
          from_date: fromDate,
          to_date: toDate,
          ro_office: ro_office,
          do_office: do_office

        },
        headers: {
          accept: 'application/json'
        }
      });

      console.log(response, "responseresponse");
      console.log(response.data, "responseresponse-datadata");
      console.log(response.data[0], "responseresponsedatadata[0]");

      const ChartOne = response.data[0].vehicle_type_claims;
      const ChartTwo = response.data[0].vehicle_type_amounts;
      const ChartThree = response.data[0].do_office_claims;
      const ChartFour = response.data[0].do_office_amounts;



      // Dynamically transform the API data into the required format
      const transformedDataForChartOne = Object.keys(ChartOne).map((key) => {
        if (key !== "None") {
          return {
            name: key,  // Dynamically set the name based on the API response key
            outstanding: ChartOne[key].o_s_claims,
            settled: ChartOne[key].settled_claims
          };
        }
        return null;
      }).filter(item => item !== null);

      const transformedDataForChartTwo = Object.keys(ChartTwo).map((key) => {
        if (key !== "None") {
          return {
            name: key,  // Dynamically set the name based on the API response key
            outstanding: ChartTwo[key].o_s_claims_amount,
            settled: ChartTwo[key].settled_claims_amount
          };
        }
        return null;
      }).filter(item => item !== null);

      const transformedDataForChartThree = Object.keys(ChartThree).map((key) => {
        if (key !== "None") {
          return {
            name: key,  // Dynamically set the name based on the API response key
            outstanding: ChartThree[key].o_s_claims,
            settled: ChartThree[key].settled_claims
          };
        }
        return null;
      }).filter(item => item !== null);

      const transformedDataForChartfour = Object.keys(ChartFour).map((key) => {
        if (key !== "None") {
          return {
            name: key,  // Dynamically set the name based on the API response key
            outstanding: ChartFour[key].o_s_claims_amount,
            settled: ChartFour[key].settled_claims_amount
          };
        }
        return null;
      }).filter(item => item !== null);

      // Set the state with the transformed data
      setClaimsDataOne(transformedDataForChartOne);
      setClaimsDataTwo(transformedDataForChartTwo);
      setClaimsDataThree(transformedDataForChartThree);
      setClaimsDataFour(transformedDataForChartfour);

    } catch (error) {
      console.error("There was an error fetching the claims data!", error);
    }
  };

  useEffect(() => {
    // Fetch the data from the API

  }, []);

  const fetchClientData = async () => {
    try {
      const clientResponse = await axios.get(`${apiUrl}clients/`, {
        headers: {
          "Content-Type": "application/json",
          //   'ngrok-skip-browser-warning': 'true'
        },
      });
      setClientOptions(clientResponse.data);
    } catch (error) {
      console.error(error.message, "error print");
    }
  };

  const fetchPolicyData = async () => {
    try {
      const policyResponse = await axios.get(`${apiUrl}policy_type/?id=${client}`, {
        headers: {
          "Content-Type": "application/json",
          //   'ngrok-skip-browser-warning': 'true'
        },
      });
      setPolicyTypes(Object.keys(policyResponse.data));
      console.log(Object.keys(policyResponse.data));
      console.log(PolicyTypes, "PolicyTypesPolicyTypes");

    } catch (error) {
      console.error(error.message, "error print");
    }
  };

  const fetchROData = async () => {
    try {
      const ROResponse = await axios.get(`${apiUrl}regional-office/`, {
        headers: {
          "Content-Type": "application/json",
          //   'ngrok-skip-browser-warning': 'true'
        },
      });
      setROOptions(ROResponse.data.claims);
    } catch (error) {
      console.error(error.message, "error print");
    }
  };

  const fetchDOData = async (data) => {
    try {
      const DOResponse = await axios.get(`${apiUrl}devisional-office/?id=${data}`, {
        headers: {
          "Content-Type": "application/json",
          //   'ngrok-skip-browser-warning': 'true'
        },
      });
      setDOOptions(DOResponse.data.claims);
    } catch (error) {
      console.error(error.message, "error print");
    }
  };

  const fetchVehicle_Type = async (data) => {
    try {
      const VehicleTypeResponse = await axios.get(`${apiUrl}vehicle_type/?id=${data}`, {
        headers: {
          "Content-Type": "application/json",
          //   'ngrok-skip-browser-warning': 'true'
        },
      });
      setVehicleOptions(VehicleTypeResponse.data.claims);
      console.log(VehicleTypeResponse);

    } catch (error) {
      console.error(error.message, "error print");
    }
  };
  const fetchBOData = async (data) => {
    try {
      const BOResponse = await axios.get(`${apiUrl}branch-office/?id=${data}`, {
        headers: {
          "Content-Type": "application/json",
          //   'ngrok-skip-browser-warning': 'true'
        },
      });
      setBOOptions(BOResponse.data.claims);
    } catch (error) {
      console.error(error.message, "error print");
    }
  };

  const fetchVehicleData = async () => {
    try {
      const VehicleResponse = await axios.get(`${apiUrl}vehicle-types/`, {
        headers: {
          "Content-Type": "application/json",
          // 'ngrok-skip-browser-warning': 'true'
        },
      });
      setVehicleTypes(VehicleResponse.data);
    } catch (error) {
      console.error(error.message, "error print");
    }
  };

  const fetchWorkShopData = async () => {
    try {
      const WorkShopResponse = await axios.get(`${apiUrl}workshop-types/`, {
        headers: {
          "Content-Type": "application/json",
          // 'ngrok-skip-browser-warning': 'true'
        },
      });
      setWorkShopTypes(WorkShopResponse.data);
    } catch (error) {
      console.error(error.message, "error print");
    }
  };

  const fetchVehicleMakesData = async () => {
  
    try {
      const VehicleMakesResponse = await axios.get(`${apiUrl}vehicle_make/?insurer_name=${client}`, {
        headers: {
          "Content-Type": "application/json",
          // 'ngrok-skip-browser-warning': 'true'
        },
      });
      console.log(VehicleMakesResponse.data.claim_ids[`${client}`], "makesssssssssssss")
      setVehicleMakesTypes(VehicleMakesResponse.data.claim_ids[`${client}`]);
      console.log(VehicleMakesTypes)
    } catch (error) {
      console.error(error.message, "error print");
    }
  };

  const fetchDistinctPolicyData = async () => {
    try {
      const DistinctPolicyResponse = await axios.get(
        `${apiUrl}distinct-policy-types/`,
        {
          headers: {
            "Content-Type": "application/json",
            // 'ngrok-skip-browser-warning': 'true'
          },
        }
      );
      setDistinctPolicyTypes(DistinctPolicyResponse.data);
    } catch (error) {
      console.error(error.message, "error print");
    }
  };

  const fetchClaimsSurvey = async () => {
    try {

      if (!client) {
        alert("please select a valid Client!")
        return;
      }
      if (!fromDate && !ro_office && !client) {
        alert("please select a valid Client!")
        return;
      }
      setIsTableLoading(true);
      var Ro_office = ro_office == "Select" ? "" : ro_office;
      var Do_office = do_office == "Select" ? "" : do_office;
      var Bo_office = bo_office == "Select" ? "" : bo_office;
      var Workshop_type = workshop_type == "Select" ? "" : workshop_type;
      var Vehicle_type = vehicle_type == "Select" || null ? "" : vehicle_type;
      let Fromdate = fromDate;

      let Todate = toDate;
      if (!toDate) {
        Todate = new Date().toISOString().split('T')[0];
      }

      let claimsSurveyResponse;
      if (!fromDate) {
        claimsSurveyResponse = await axios.get(
          `${apiUrl}get_report/?client=${client}&ro_office=${Ro_office}&do_office=${Do_office}&bo_office=${Bo_office}&vehicle_type=${Vehicle_type}&workshop_type=${Workshop_type}`,
          // `${apiUrl}claims-summary/?start_date=${fromDate}&end_date=${toDate}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        claimsSurveyResponse = await axios.get(
          `${apiUrl}get_report/?client=${client}&ro_office=${Ro_office}&do_office=${Do_office}&bo_office=${Bo_office}&vehicle_type=${Vehicle_type}&workshop_type=${Workshop_type}&from_date=${Fromdate}&to_date=${Todate}`,
          // `${apiUrl}claims-summary/?start_date=${fromDate}&end_date=${toDate}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }
      setClaimsSurveyRange(claimsSurveyResponse.data.claims);
      console.log(claimsSurveyResponse);
      console.log(claimsSurveyResponse.data);

    } catch (error) {
      console.error(error.message);
    } finally {
      setIsTableLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    try {
      await Promise.all([
        fetchVehicleData(),
        fetchClientData(),
        fetchROData(),
        fetchWorkShopData(),

      ]);
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData()
  }, []);







  // Function to handle client selection and fetch vehicle types
  async function GetClient(value) {
    setClient(value);
    await fetchVehicle_Type(value); // Await for consistency and ensure data is fetched
  }

  // Function to handle RO selection and fetch DO and vehicle types data
  async function GetDOList(value) {
    setRO_office(value);
    if (value === "") {
      // Pass an empty string or a specific parameter if the API expects it for fetching all data
      await fetchClientData();
      await fetchDOData(); // or "all" or any parameter your API expects
      await fetchVehicle_Type();
    } else {
      // Fetch specific data based on value
      await fetchDOData(value);
      await fetchVehicle_Type(value);
    }
  }

  // Function to handle DO selection and fetch BO data
  async function GetBOList(value) {
    setDO_office(value);
    if (value === "") {
      await fetchBOData(); // Handle the "All" case for BO as well
    } else {
      await fetchBOData(value);
    }
  }

  // Function to handle BO selection and set state (no fetching)
  async function GetList(value) {
    setBO_office(value);
    // Add additional logic here if needed when "All" is selected or specific values
    if (value === "") {
      // If you want to fetch all related data when "All" is selected
      // fetchDataForAllBO(); // Optional: If you have a function to handle this
    }
  }


  return (
    <div style={{
      backgroundColor: '#e7e7e7', height: '100%'
    }}>
      <h1>Claims MIS Report</h1>

      {/* Filter Section */}
      {
        isLoading ? (
          <Loader />
        ) :
          (
            <>
              <div className="container">
                <div className="filter-section">
                  <div className="row mb-3 align-items-center">
                    <div className="col-md-4 text-end">
                      <label className="form-label">Client:</label>
                    </div>
                    <div className="col-md-4">
                      <select
                        className="form-control"
                        value={client}
                        onChange={(e) => GetClient(e.target.value)}
                      >
                        <option value="" disabled hidden>
                          Select
                        </option>
                        {clientOptions.map((item, index) => (
                          <option value={item.id} key={index}>
                            {item.companyName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 align-items-center">
                    <div className="col-md-4 text-end">
                      <label className="form-label">Office:</label>
                    </div>
                    <div className="col-md-2">
                      <select className="form-control" value={ro_office} onChange={(e) => GetDOList(e.target.value)}>
                        
                        <option value="">All</option>
                        {ROOptions.map((item, index) => (
                          <option value={item.claims} key={index}>
                            {item.firstName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <select className="form-control" value={do_office} onChange={(e) => GetBOList(e.target.value)}>
                        
                        <option value="">All</option>
                        {DOOptions.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <select className="form-control" value={bo_office} onChange={(e) => setBO_office(e.target.value)}>
                       
                        <option value="">All</option>
                        {BOOptions.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 align-items-center">
                    <div className="col-md-2 text-end">
                      <label className="form-label">Vehicle Type:</label>
                    </div>
                    <div className="col-md-4">
                      <select className="form-control" value={vehicle_type} onChange={(e) => setVehicle_type(e.target.value)}>
                        
                        <option value="" >All</option>
                        {vehicleOptions.map((item, index) => (
                          <option value={item.vehicle_type} key={index}>
                            {item.vehicle_type}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-2 text-end">
                      <label className="form-label">Vehicle Make:</label>
                    </div>
                    <div className="col-md-4">
                    <select className="form-control" value={VehicleMakeTypes} onChange={(e) => setVehicleMakeTypes(e.target.value)}>
                       
                        <option value="">All</option>
                        {VehicleMakesTypes.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 align-items-center">
                    <div className="col-md-2 text-end">
                      <label className="form-label">Workshop Type:</label>
                    </div>
                    <div className="col-md-4">
                      <select className="form-control" value={workshop_type} onChange={(e) => setWorkshop_type(e.target.value)}>
                       
                        <option value="">All</option>
                        {WorkShopTypes.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-2 text-end">
                      <label className="form-label">Policy Type:</label>
                    </div>
                    <div className="col-md-4">
                      <select className="form-control" value={bo_office} onChange={(e) => setBO_office(e.target.value)}>
                       
                        <option value="All">All</option>
                        {PolicyTypes.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>

                    </div>
                  </div>
                  <div className="row mb-3 align-items-center">
                    <div className="col-md-2 text-end">
                      <label className="form-label">From Date:</label>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="date"
                        className="form-control"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                      />
                    </div>

                    <div className="col-md-2 text-end">
                      <label className="form-label">To Date:</label>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="date"
                        className="form-control"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div
                      className="col"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button
                        className="btn btn-primary ms-2"
                        onClick={() => {
                          fetchPolicyData()
                          fetchVehicleMakesData()
                          fetchClaimsSurvey()
                          fetchBarCharts()
                          fetchDistinctPolicyData()

                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Table Section */}
              <ReportTable data={claimsSurveyRange} isTableLoading={isTableLoading} />

              {/* Chart Section */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between", // Adjust spacing between items
                  marginTop: 50,
                  width: "100%", // Ensure full width
                  flexWrap: "wrap", // Allow wrapping to next line if needed
                }}
              >
                <div className="mt-3" style={{ flex: "1 1 calc(25% - 10px)", margin: "5px" }}>
                  <h5>Vehicle Type - No of Claims</h5>
                  <ClaimsChart
                    data={claimsDataOne}
                    bars={[
                      { dataKey: "outstanding", fill: "#8884d8" },
                      { dataKey: "settled", fill: "#58A8A3" }
                    ]}
                  />
                </div>


                <div className="mt-3" style={{ flex: "1 1 calc(25% - 10px)", margin: "5px" }}>
                  <h5>Vehicle Type - Claim Amount</h5>
                  <ClaimsChart
                    data={claimsDataTwo}
                    bars={[
                      { dataKey: "outstanding", fill: "#8884d8" },
                      { dataKey: "settled", fill: "#58A8A3" }
                    ]}
                  />
                </div>

                <div className="mt-3" style={{ flex: "1 1 calc(25% - 10px)", margin: "5px" }}>
                  <h5>Do Office - No of Claims</h5>
                  <ClaimsChart
                    data={claimsDataThree}
                    bars={[
                      { dataKey: "outstanding", fill: "#8884d8" },
                      { dataKey: "settled", fill: "#58A8A3" },
                    ]}
                  />
                </div>

                <div className="mt-3" style={{ flex: "1 1 calc(25% - 10px)", margin: "5px" }}>
                  <h5>Do Office - Claim Amount</h5>
                  <ClaimsChart
                    data={claimsDataFour}
                    bars={[
                      { dataKey: "outstanding", fill: "#8884d8" },
                      { dataKey: "settled", fill: "#58A8A3" },
                    ]}
                  />
                </div>
              </div>

            </>
          )
      }
    </div>
  );
}
export default Dashboard;
