import React from 'react';
import './App.css';
import Dashboard from './DashBoard';
import LoginScreen from './LoginScreen';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { BaseRoutes } from './CommonConfig/BaseRoutes';
import Fraud from './Fraud';
import DamageAnalysis from './DamageAnalysisDashboard';

function App() {
  return (
    <div className="App">
       <BrowserRouter>
        <Routes>
          <Route path={BaseRoutes.Dashboard} element={<Dashboard />}></Route>
          <Route path={BaseRoutes.FraudDashboard} element={<Fraud />}></Route>
          <Route path={BaseRoutes.DamageAnalysisDashboard} element={<DamageAnalysis />}></Route>
          {/* <Route path={BaseRoutes.Signin} element={<LoginScreen />}></Route> */}
        </Routes>
      </BrowserRouter>
      {/* <Dashboard /> */}
    </div>
  );
}

export default App;
