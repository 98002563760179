// FraudClaimsChart.js
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
  { name: 'Private Car', outstanding: 550, settled: 1950 },
  { name: 'Office Car', outstanding: 5000, settled: 1000 },
];

const FraudClaimsChart = ({ data, bars }) => {
    return (
      <BarChart width={400} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {bars.map((bar, index) => (
          <Bar key={index} dataKey={bar.dataKey} fill={bar.fill} />
        ))}
      </BarChart>
    );
  };
  
  
  export default FraudClaimsChart;