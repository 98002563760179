import React from 'react';

function FraudReportTable({data, isTableLoading}) { 
  // const reportData = data || {};
  const reportData = [
    { 
      totalEstimate: 10000, 
      afterDepreciation: 8500, 
      settledAmount: 8000, 
      difference: 1500, 
      differencePercent: (1500 / 10000) * 100 
    },
    { 
      totalEstimate: 12000, 
      afterDepreciation: 10000, 
      settledAmount: 9500, 
      difference: 2500, 
      differencePercent: (2500 / 12000) * 100 
    },
    { 
      totalEstimate: 9000, 
      afterDepreciation: 7000, 
      settledAmount: 6500, 
      difference: 2500, 
      differencePercent: (2500 / 9000) * 100 
    },
    { 
      totalEstimate: 11000, 
      afterDepreciation: 9500, 
      settledAmount: 9000, 
      difference: 2000, 
      differencePercent: (2000 / 11000) * 100 
    }
  ];
  const dailySummaries = reportData || [];

  const tableStyle = {
    width: '100%',
    tableLayout: 'fixed',
    fontSize: '14px',
  };

  const thTdStyle = {
    padding: '8px',
    border: '1px solid #ddd',
    textAlign: 'center',
    wordWrap: 'break-word',
  };

  const thStyle = {
    ...thTdStyle,
    backgroundColor: '#fff',
    fontWeight: 'bold',
  };

  const tdStyle = {
    ...thTdStyle,
    backgroundColor: '#fff',
  };

  const smallScreenStyle = {
    fontSize: '12px',
  };

  const isSmallScreen = window.innerWidth <= 768;

  return (
    <table style={isSmallScreen ? { ...tableStyle, ...smallScreenStyle } : tableStyle}>
      <thead>
        <tr>
          <th style={thStyle}>S.No.</th>
          <th style={thStyle}>Total Estimate Amount</th>
          <th style={thStyle}>Estimate After Depreciation Amount</th>
          <th style={thStyle}>Settled Amount</th>
          <th style={thStyle}>Difference</th>
          <th style={thStyle}>Difference %</th>
        </tr>
      </thead>
      <tbody>
        {isTableLoading ? (
          <tr>
            <td colSpan="6" style={{textAlign: 'center', backgroundColor: '#fff'}}>Loading...</td>
          </tr>
        ) : dailySummaries?.length === 0 ? (
          <tr>
            <td colSpan="6" style={{ textAlign: 'center', backgroundColor: '#fff' }}>
              No Data
            </td>
          </tr>
        ) : (
          reportData?.map((row, index) => (
            <tr key={index}>
              <td style={tdStyle}>{index + 1}</td>
              <td style={tdStyle}>{row.totalEstimate}</td>
              <td style={tdStyle}>{row.afterDepreciation}</td>
              <td style={tdStyle}>{row.settledAmount}</td>
              <td style={tdStyle}>{row.difference}</td>
              <td style={tdStyle}>{row.differencePercent.toFixed(2)}%</td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
}

export default FraudReportTable;
